<template>
  <div id="app">
    <Home />
    <ImgCarousel />
  </div>
</template>

<script>
import Home from './components/Home.vue'
import ImgCarousel from './components/ImgCarousel.vue'

export default {
  name: 'App',
  components: {
    Home,
    ImgCarousel
  }
}
</script>

<style>
#app {
  box-sizing: border-box;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #444;
}
</style>
