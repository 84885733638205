export default class Person {
    constructor(person = null){
        try{
            if(person){
                Object.assign(this, person)
            }else{
                this.buildEmpty()
            }
        }catch(e){
            this.buildEmpty()
        }
    }

    buildEmpty(){
        this.name = null,
        this.docType = '01',
        this.doc = null,
        this.address = null,
        this.district = null,
        this.city = null,
        this.state = 'GO',
        this.zipcode = null,
        this.carLicense = null,
        this.itauShoplineCode = null
    }
}