<template>
  <div id="background">
    <b-carousel
      id="carousel-fade"
      :interval="5000"
      fade
    >
      <b-carousel-slide v-for="(slide, index) in slides" :key="index">
        <template v-slot:img>
            <img
            class="d-block slides-eurovistorias"
            width="1024"
            :src="slide"
            alt="image slot">
            </template>
        </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
  export default {
    name: 'ImgCarousel',
    data() {
      return {
        slide: 0,
        sliding: null,
        slides: [
            "images/Euroex01.jpg",
            "images/Euroex02.jpg",
            "images/Euroex03.jpg",
            "images/Euroex04.jpg",
            "images/Euroex05.jpg",
            "images/Euroex06.jpg",
            "images/Euroex07.jpg",
            "images/Euroex08.jpg",
            "images/Euroex09.jpg",
        ]
      }
    },
    methods: {
      onSlideStart() {
        this.sliding = true
      },
      onSlideEnd() {
        this.sliding = false
      }
    }
  }
</script>
<style scoped>
    #background{
      position: absolute;
      top: 0;
      height: 0;
      background: #999;
    }
    .slides-eurovistorias {
        opacity: 0.1;
        width:100vw;
        height:100vh;
        object-fit: cover;
    }
</style>