<template>
  <div id="home">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6 mb-4">
          <div class="box h30 orange-stripe-top mb-4">
            <img id="logo" src="logo.svg" />
          </div>
          <div class="box h70 mb-4">
            <div class="contacts">
              <h3 class="box-header">
                <div class="pn-icon">
                  <div class="icon"><b-icon icon="calendar2-check"></b-icon></div>
                </div>
                <div class="title">AGENDE A SUA VISTORIA</div>
              </h3>
              <h4>
                (62) 3578-1164<br />
                <small>Avenida Goiás, Qd 3 Lt 8 - Vera Cruz Aparecida de Goiânia - GO</small>
              </h4>
              <hr>
              <div class="item text-center">
                <a href="https://laudos.otmws.com.br/agendamentovistorias/go/24232989000127" target="_blank">
                  <div class="button big">
                    <div class="elems">
                      <b-icon icon="calendar-check" class="btn-icon"></b-icon> 
                      <div class="call-to-action">Agende aqui</div>
                    </div>
                  </div>
                </a>
              </div>
              <hr>
              <div class="item text-center">
                <a href="https://wa.me/+556235781164" target="_blank">
                  <div class="button whatsapp">
                    <div class="elems">
                      <div class="btn-icon whatsapp"></div> 
                      <div class="call-to-action">WhatsApp</div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="item text-center">
                <a href="tel:+556235781164" target="_blank">
                  <div class="button">
                    <div class="elems">
                      <b-icon icon="telephone-fill" class="btn-icon"></b-icon> 
                      <div class="call-to-action">Ligar</div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="item text-center">
                <a href="https://www.google.com.br/maps/dir//-16.8316143,-49.2439501/@-16.831373,-49.2466001,17z/data=!4m2!4m1!3e4" target="_blank">
                  <div class="button">
                    <div class="elems">
                      <b-icon icon="geo-alt-fill" class="btn-icon"></b-icon> 
                      <div class="call-to-action">Chegar</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 mt-4 mt-md-0">
          <div class="box">
            <h3 class="box-header">
                <div class="icon"><b-icon icon="cash-stack"></b-icon></div>
                <div class="title">GERE O SEU BOLETO</div>
              </h3>
            <FormItauShopline />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import FormItauShopline from './FormItauShopline.vue'
  export default {
    name: 'Home',
    components:{
      FormItauShopline
    },
    props: {
      msg: String
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #home{
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 750px;
    min-height: 100vh;
    text-align: center;
    box-sizing: border-box;
    z-index: 999;
  }
  h4{
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  h4 small{
    display: block;
    font-weight: normal;
    padding-top: 5px;
  }
  .box{
    background: #FFF;
    padding: 30px;
    justify-content: center;
    align-items: center;
    height: 100%;
    box-shadow: 0 0 30px #00000033;
  }
  .box.h70{
    height: 65%;
    display: flex;
    align-items: center;
  }
  .box.h30{
    height: 35%;
    display: flex;
    align-items: center;
  }
  .box.orange-stripe-top{
    border-top: 10px solid #f15e24;
  }
  .box-header{
    display: flex;
    font-size: 20px;
    font-weight: bold;
    color: #f15e24;
    margin: 20px 0;
    align-items: center;
  }  

  .box-header div{
    display: inline-block;
  }

  .box-header .icon{
    width: 45px;
    height: 45px;
    border-radius: 50%;
    overflow: hidden;
    background: #f15e24;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    margin-right: 10px;
  }

  #logo{
    width: 100%;
    margin-bottom: 40px;
  }
  .sep{
    border: 1px solid #CCC;
    border-top: none;
    margin: 20px 0;
  }
  .contacts{
    text-align: left;
  }
  .contacts .item{
    margin-bottom: 10px;
  }

  .contacts .item a{
    text-decoration: none;
  }
  
  .contacts .item a .button{
    background: linear-gradient(45deg, #EE3300, #f15e24);
    border: none;
    padding: 5px 20px;
    border-radius: 30px;
    border: 2px solid #f15e24;
    color: #FFF;
    width: 180px;
    box-shadow: 2px 2px 4px #CCC;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .contacts .item a .button.big{
    transform: scale(1.2);
  }

  .contacts .item a .button.whatsapp{
    background: linear-gradient(45deg, #27b53f, #51ce5f);
    border: 2px solid #51ce5f;
    padding: 20px;
    margin-bottom: 2px;
  }
  
  .whatsapp{
    background: url('/images/whatsapp-logo-2022.svg');
    width: 25px;
    height: 25px;
    margin-bottom: -5px;
    background-size: cover;
  }
  .btn-icon{
    display: inline-block;
    font-size: 1em;
    margin-right: 5px;
    color: #FFF;
   }

   .call-to-action{
    display: inline-block;
    font-size: 1em;
   }
</style>
