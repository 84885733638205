import api from './config'

class itauShoplineService {
    getCode(person){
        const formData = new FormData();
        Object.keys(person).forEach(key => formData.append(key, person[key]));
        return api.post('/', formData)
    }
}

export default new itauShoplineService()