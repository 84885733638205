<template>
    <form id="form-itau-shopline" :action="action" :method="method" target="_blank" @submit.prevent="go">
        <input type="hidden" name="DC" v-model="person.itauShoplineCode" />
        <fieldset>
            <label>Nome</label>
            <input type="text" name="name" v-model="person.name" />
        </fieldset>
        <div class="row mb-3">
            <div class="col-6 nowrap">
                <input type="radio" name="docType" v-model="person.docType" :value="'01'" /> <small>Pessoa Física</small>
            </div>
            <div class="col-6 nowrap">
                <input type="radio" name="docType" v-model="person.docType" :value="'02'" /> <small>Pessoa Jurídica</small>
            </div>
        </div>
        <fieldset>
            <label>{{this.labelDoc[person.docType]}}</label>
            <input type="text" name="doc" v-model="person.doc" />
        </fieldset>
        <fieldset>
            <label>Endereço</label>
            <input type="text" name="address" v-model="person.address" />
        </fieldset>
        <fieldset>
            <label>Bairro</label>
            <input type="text" name="district" v-model="person.district" />
        </fieldset>
        <fieldset>
            <label>Cidade</label>
            <input type="text" name="city" v-model="person.city" />
        </fieldset>
        <fieldset>
            <label>UF</label>
            <select name="state" v-model="person.state">
                <option v-for="state of states" :key="state.uf" :value="state.uf">
                    {{ state.uf }} - {{ state.name }}
                </option>
            </select>
        </fieldset>
        <fieldset>
            <label>CEP</label>
            <input type="text" name="zipcode" v-model="person.zipcode" />
        </fieldset>
        <fieldset>
            <label>Placa do Veículo</label>
            <input type="text" name="carLicense" v-model="person.carLicense" />
        </fieldset>
        <input type="submit" value="Gerar boleto" />
    </form>
</template>

<script>
    import FormItauShopline from '../services/itau-shopline'
    import Person from '../models/Person'
    import States from '../helpers/states'

    export default {
        name: 'FormItauShopline',
        data(){
            return {
                action: 'https://shopline.itau.com.br/shopline/shopline.aspx',
                method: 'GET',
                person: new Person(),
                states: [],
                labelDoc: [],
                allowCookies: false
            }
        },
        mounted(){
            this.states = States
            this.labelDoc['01'] = 'CPF';
            this.labelDoc['02'] = 'CNPJ';
        },
        methods:{
            go(){
                FormItauShopline.getCode(this.person)
                .then(resp =>{
                    if(resp.data.code){
                        this.person.itauShoplineCode = resp.data.code
                        setTimeout(function() {
                            document.getElementById('form-itau-shopline').submit()
                        }, 500);
                    }
                })
            },
        }
    }
</script>

<style scoped>
    fieldset{
        position: relative;
        padding: 3px 0px;
        padding-top: 13px;
        margin-bottom: 15px;
        width: 100%;
        border: 1px solid #CCC;
        border-top: none;
        border-right: none;
        background: #EEE;
    }
    select, input[type="text"]{
        width: 100%;
        text-align: left;
        border: none;
        background: #EEE;
        padding: 2px;
        padding-left: 5px;
    }
    input[type="text"]:focus, select:focus{
        outline: none;
    }
    input[type=submit]{
        text-align: center;
    }
    label{
        position: absolute;
        top: -2px;
        font-size: 11px;
        padding: 3px;
        margin-left: 1px;
        color: #f15e24;
        z-index: 1;
    }
    .nowrap{
        white-space: nowrap;
    }

    small{
        font-size: 12px;
    }

    input[type="submit"]{
        background: linear-gradient(45deg, #EE3300, #f15e24);
        border: none;
        padding: 5px 10px;
        border-radius: 2px;
        border: 2px solid #f15e24;
        color: #FFF;
        box-shadow: 2px 2px 4px #CCC;
    }
    input[type="submit"]:hover{
        background: linear-gradient(45deg, #f15e24, #f15e24);
    }
    input[type="submit"]:active{
        box-shadow: none;
    }
</style>